<template>
	<div>
		<v-btn
			fab
			color="primary"
			elevation="0"
			@click="showModal = !showModal"
			class="floating-button"
		>
			<v-icon>mdi-chat</v-icon>
		</v-btn>

		<div class="chatbox-contenedor elevation-24" v-if="showModal">
			<div class="contenedor">
				<v-row style="max-height: 70px !important">
					<v-col cols="12" sm="10" class="px-0 mx-0"
						><h2 class="text-center primary py-2 white--text pl-8">
							Chat
						</h2></v-col
					>
					<v-col cols="12" sm="2" class="primary px-0 mx-0"
						><v-btn
							icon
							dark
							@click="showModal = !showModal"
							class="primary white--text"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn></v-col
					>
					<v-col></v-col>
				</v-row>

				<div class="messageBox mt-0">
					<div
						v-for="(message, index) in messages"
						:key="index"
						:name="`item-${index}`"
					>
						<div
							:class="
								message.from == 'user'
									? 'messageFromUser'
									: 'messageFromChatGpt'
							"
						>
							<div
								:class="
									message.from == 'user'
										? 'userMessageWrapper'
										: 'chatGptMessageWrapper'
								"
							>
								<div
									:class="
										message.from == 'user'
											? 'userMessageContent'
											: 'chatGptMessageContent'
									"
									v-html="message.data"
								></div>
							</div>
						</div>
						<div :name="`loading-${index}`"></div>
					</div>
				</div>
				<div class="inputContainer">
					<input
						v-model="currentMessage"
						type="text"
						class="messageInput"
						placeholder="Haz una pregunta..."
						@keyup.enter="sendMessage(currentMessage)"
					/>
					<v-btn fab dark small color="primary">
						<v-icon @click="sendMessage(currentMessage)" class="white--text"
							>mdi-send</v-icon
						>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>




<script>
	import axios from "axios";

	export default {
		name: "ChatBox",
		data() {
			return {
				showModal: false,
				currentMessage: "",
				messages: [],
			};
		},
		methods: {
			async sendMessage(message) {
				if (message != "") {
					this.messages.push({
						from: "user",
						data: message,
					});
					this.currentMessage = "";

					setTimeout(() => {
						this.messages.push({
							from: "chatGpt",
							data: "<b>Chat Online fuera de Servicio.</b> <br> Por favor cualquier consulta que tengas envia un mail a <b><u>socios@acara.org.ar</u></b> o comunicate al <b><u>(011) 5236-6500</b></u> de lunes a viernes hábiles de 8 a 18 ",
						});
					}, 1000);
					/*await axios
																																																																			          .post(
																																																																			            "https://api.openai.com/v1/completions",
																																																																			            {
																																																																			              model: "gpt-3.5-turbo",
																																																																			              prompt: message,
																																																																			              max_tokens: 20,
																																																																			            },
																																																																			            {
																																																																			              headers: {
																																																																			                "Content-Type": "application/json",
																																																																			                Authorization: `Bearer sk-proj-6VuWokkMANKeCukrKCJwT3BlbkFJthFzimq2sSdB2EnP6HG3`,
																																																																			              },
																																																																			            }
																																																																			          )
																																																																			          .then((response) => {
																																																																			            this.messages.push({
																																																																			              from: "chatGpt",
																																																																			              data: response.data.choices[0].text.trim(),
																																																																			            });
																																																																			          })
																																																																			          .catch((error) => {
																																																																			            console.error("Error:", error);
																																																																			            this.messages.push({
																																																																			              from: "chatGpt",
																																																																			              data: "No conectado",
																																																																			            });
																																																																			          });
																																																																			        */
				} else {
					this.messages.push({
						from: "chatGpt",
						data: "Debes ingresar una consulta",
					});
				}
			},
		},
	};
</script>

<style>
.floating-button {
	position: fixed;
	bottom: 16px;
	right: 16px;
	z-index: 1000;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.chatbox-contenedor {
	position: fixed;
	bottom: 24px !important;
	right: 74px !important;
	z-index: 1000;
}

.contenedor {
	width: 360px;
	height: 500px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	font-family: "Roboto", sans-serif;
}

.messageBox {
	padding: 16px;
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #fafafa;
}

.messageFromChatGpt {
	float: right;
}
.messageFromUser,
.messageFromChatGpt {
	display: flex;
}

.messageBox {
	max-height: 400px;
	overflow-y: auto;
	padding: 0 16px;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	flex-grow: 1;
}

.messageFromUser,
.messageFromChatGpt {
	display: flex;
	margin-bottom: 8px;
}

.userMessageWrapper,
.chatGptMessageWrapper {
	display: flex;
	flex-direction: column;
}

.userMessageWrapper {
	align-self: flex-end;
}

.chatGptMessageWrapper {
	align-self: flex-start;
}

.userMessageContent,
.chatGptMessageContent {
	max-width: 100%;
	padding: 8px 12px;
	border-radius: 18px;
	margin-bottom: 2px;
	font-size: 14px;
	line-height: 1.4;
}

.userMessageContent {
	background-color: #1877f2;
	color: white;
	border-top-left-radius: 0;
}

.chatGptMessageContent {
	background-color: #ededed;
	color: #222;
	border-top-right-radius: 0;
}

.userMessageTimestamp,
.chatGptMessageTimestamp {
	font-size: 10px;
	color: #999;
	margin-top: 2px;
}

.userMessageTimestamp {
	align-self: flex-end;
}

.chatGptMessageTimestamp {
	align-self: flex-start;
}

.inputContainer {
	display: flex;
	align-items: center;
	padding: 8px;
	background-color: #e0e0e0;
}

.messageInput {
	flex-grow: 1;
	border: none;
	outline: none;
	padding: 12px;
	font-size: 16px;
	background-color: white;
	border-radius: 24px;
	margin-right: 8px;
}

.askButton {
	background-color: #1877f2;
	color: white;
	font-size: 16px;
	padding: 8px 10px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 24px;
	transition: background-color 0.3s ease-in-out;
}

.askButton:hover {
	background-color: #145cb3;
}

@media (max-width: 480px) {
	.contenedor {
		width: 100%;
		max-width: none;
		border-radius: 0;
	}
}
.chatbox-contenedor {
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: 1000;
}

.messageBox {
	padding: 16px;
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.messageFromUser,
.messageFromChatGpt {
	display: flex;
}
</style>
