var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"floating-button",attrs:{"fab":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_c('v-icon',[_vm._v("mdi-chat")])],1),(_vm.showModal)?_c('div',{staticClass:"chatbox-contenedor elevation-24"},[_c('div',{staticClass:"contenedor"},[_c('v-row',{staticStyle:{"max-height":"70px !important"}},[_c('v-col',{staticClass:"px-0 mx-0",attrs:{"cols":"12","sm":"10"}},[_c('h2',{staticClass:"text-center primary py-2 white--text pl-8"},[_vm._v(" Chat ")])]),_c('v-col',{staticClass:"primary px-0 mx-0",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"primary white--text",attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col')],1),_c('div',{staticClass:"messageBox mt-0"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,attrs:{"name":`item-${index}`}},[_c('div',{class:message.from == 'user'
								? 'messageFromUser'
								: 'messageFromChatGpt'},[_c('div',{class:message.from == 'user'
									? 'userMessageWrapper'
									: 'chatGptMessageWrapper'},[_c('div',{class:message.from == 'user'
										? 'userMessageContent'
										: 'chatGptMessageContent',domProps:{"innerHTML":_vm._s(message.data)}})])]),_c('div',{attrs:{"name":`loading-${index}`}})])}),0),_c('div',{staticClass:"inputContainer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentMessage),expression:"currentMessage"}],staticClass:"messageInput",attrs:{"type":"text","placeholder":"Haz una pregunta..."},domProps:{"value":(_vm.currentMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage(_vm.currentMessage)},"input":function($event){if($event.target.composing)return;_vm.currentMessage=$event.target.value}}}),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary"}},[_c('v-icon',{staticClass:"white--text",on:{"click":function($event){return _vm.sendMessage(_vm.currentMessage)}}},[_vm._v("mdi-send")])],1)],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }